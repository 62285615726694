<script>
export default{
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
}</script>

<template>
<div class="footer1">
    <div class="footer-text">
      <h1>Your Health is our <span>#1</span> priority</h1>
    </div>

    <div class="footer-content">
      <div class="footer-left">
        <div class="footer-logo">
          <img src="/img/logo-doctosoft.491c6828.png" alt="" height="30" />
        </div>
        <div class="store">
          <div class="playstore">
            <router-link to=""
              ><img src="../../../assets/images/playstore.png"
            /></router-link>
          </div>
          <div class="appstore">
            <router-link to="#"
              ><img src="../../../assets/images/appstore.png"
            /></router-link>
          </div>
        </div>
        <div class="social">
          <div class="instagram">
            <router-link to="#"><i class="ri-instagram-line"></i></router-link>
          </div>
          <div class="twitter">
            <router-link to="#"> <i class="ri-twitter-line"></i></router-link>
          </div>
          <div class="facebook">
            <router-link to="#"><i class="ri-facebook-line"></i></router-link>
          </div>
          <div class="youtube">
            <router-link to="#"> <i class="ri-youtube-line"></i></router-link>
          </div>
        </div>
      </div>
      <div class="footer-right">
        <div class="company">
          <router-link to="#"><h3>Company</h3></router-link>
          <router-link to="#"><p>About Us</p></router-link>
          <router-link to="#"><p>Blog</p></router-link>
          <router-link to="#"><p>Career</p></router-link>
          <router-link to="#"><p>Press</p></router-link>
        </div>
        <div class="customer-care">
          <router-link to="#"><h3>Customer Care</h3></router-link>
          <router-link to="#"><p>Contact</p></router-link>
          <router-link to="#"><p>FAQ</p></router-link>
          <router-link to="#"><p>Help</p></router-link>
          <router-link to="#"><p>Support</p></router-link>
        </div>
        <div class="services">
          <router-link to="#"><h3>Our Services</h3></router-link>
          <router-link to="#"><p>Consultation</p></router-link>
          <router-link to="#"><p>Lab Tests</p></router-link>
          <router-link to="#"><p>Surgeries</p></router-link>
          <router-link to="#"><p>Care Plans</p></router-link>
        </div>
      </div>
    </div>
    <div class="footer-line">
      <p>
        Our vision is to help mankind live healthier, longer lives by making
        quality healthcare accessible, affordable and convenient.
        <span
          >{{ currentYear }} © DoctoSoft™ Pvt Ltd. All rights reserved.</span
        >
      </p>
    </div>
  </div>
</template>


<style>
.footer1 {
  height: 380px;
  background-color: #d1eefe;
  position: relative;
}
.footer-text {
  text-align: center;
  padding: 20px 0 0 0;
  cursor: default;
}

.footer-text h1 span {
  color: #ec5a60;
}

.footer-content {
  margin: 20px;
  margin-bottom: 0;
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #ec5a60;
}
.footer-logo {
  padding: 5px;
}

.footer-left {
  width: 400px;
  height: fit-content;
}

.footer-left .store {
  display: flex;
  padding: 10px;
  margin-top: 20px;
  overflow: hidden;
  height: fit-content;
  gap: 10px;
}
.store .playstore {
  width: 150px;
  height: fit-content;
}
.store .playstore img {
  width: 100%;
}
.store .appstore {
  width: 135px;
  height: fit-content;
}
.store .appstore img {
  width: 100%;
}
.social {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  padding: 10px;
  width: 60%;
  /* margin-bottom: 20px; */
}
.social a {
  font-size: 2rem;
}

.social .instagram a {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  -webkit-background-clip: text;

  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.social .twitter a {
  color: #00acee;
}

.social .facebook a {
  color: #3b5998;
}
.social .youtube a {
  color: #ff0000;
}
.footer-right {
  width: 100%;
  /* border: 1px solid black; */
  height: fit-content;
  margin-left: 10px;
  display: flex;
  padding: 5px;
}

.footer-right .company {
  margin-left: 150px;
}
.footer-right .company h3 {
  margin-bottom: 20px;
}
.footer-right .company p {
  margin: 0;
  font-size: 14px;
  margin-top: 5px;
}
.footer-right .customer-care {
  margin-left: 150px;
}
.footer-right .customer-care h3 {
  margin-bottom: 20px;
}
.footer-right .customer-care p {
  margin: 0;
  font-size: 14px;
  margin-top: 5px;
}
.footer-right .services {
  margin-left: 150px;
}
.footer-right .services h3 {
  margin-bottom: 20px;
}
.footer-right .services p {
  margin: 0;
  font-size: 14px;
  margin-top: 5px;
}

.footer-line p {
  padding: 10px 0 0 0;
  text-align: center;
  font-size: 15px;
}

.footer-line p span {
  font-size: 14px;
  display: block;
  font-weight: 600;
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1440px) {
  .footer1 {
    height: fit-content;
  }

  .footer-text {
    padding: 10px 0 0 0;
  }
  .footer-text h1 {
    font-size: 25px;
  }

  .footer-content {
    margin: 15px;
    padding: 7px;
  }

  .footer-left {
    width: 220px;
  }
  .footer-logo img {
    height: 25px;
  }
  .footer-left .store {
    padding: 5px;
    margin-top: 10px;
  }
  .footer-left .social {
    margin-top: 10px;
  }

  .social a {
    font-size: 1.9rem;
  }

  .footer-right .company {
    margin-left: 60px;
  }

  .footer-right .customer-care {
    margin-left: 60px;
  }

  .footer-right .services {
    margin-left: 60px;
  }

  .footer-right .company h3 {
    margin-bottom: 15px;
  }
  .footer-right .customer-care h3 {
    margin-bottom: 15px;
  }
  .footer-right .services h3 {
    margin-bottom: 15px;
  }
  .footer-right .customer-care {
    margin-left: 100px;
  }

  .footer-right .services {
    margin-left: 100px;
  }

  .footer-line p {
    font-size: 13px !important;
    margin: 0;
    padding: 5px;
    text-align: center;
  }

  .footer-line p span {
    font-size: 11px;
    margin-left: 0;
    padding: 5px;
    display: block;
    text-align: center;
    width: 100%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .footer-line p {
    font-size: 13px !important;
    margin: 0;
    padding: 0px;
    text-align: center;
  }
  .footer-right .company {
    margin-left: 0;
  }
  .footer-left {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .footer-line p span {
    font-size: 11px;
    margin-left: 0;
    padding: 5px;
    display: block;
    text-align: center;
    width: 100%;
  }

  .footer-text h1 {
    font-size: 24px;
  }
  .footer-right {
    margin: 0;
    justify-content: space-around;
    align-items: center;
  }
  .footer-right .company h3 {
    font-size: 20px !important;
    margin-bottom: 0 !important;
  }
  .footer-right .company p {
    font-size: 14px !important;
  }
  .footer-right .customer-care h3 {
    font-size: 20px !important;
    margin-bottom: 0 !important;
  }
  .footer-right .customer-care p {
    font-size: 14px !important;
  }
  .footer-right .services h3 {
    font-size: 20px !important;
    margin-bottom: 0 !important;
  }
  .footer-right .services p {
    font-size: 14px !important;
  }
  .footer-right .customer-care {
    margin-left: 20px;
  }
  .footer-right .services {
    margin-left: 20px;
  }
  .footer-right .company h3 {
    margin-bottom: 20px;
    font-size: 20px;
  }

  .footer-content {
    flex-direction: column;
  }

  .footer1 {
    height: fit-content;
  }
  .store .appstore img {
    width: 80%;
  }
  .store .playstore img {
    width: 80%;
  }
  .social {
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
    width: 25%;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }
  .footer-left {
    /* width: 100%; */
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .footer-line p span {
    font-size: 11px;
    margin-left: 0;
    padding: 5px;
    display: block;
    text-align: center;
    width: 100%;
  }
  .footer-right {
    display: flex;
    align-items: flex-start;
    margin: 0;
    justify-content: center;
  }
  .footer-text h1 {
    font-size: 20px !important;
  }
  .footer-right .company {
    margin: 0;
  }
  .footer-right .company h3 {
    font-size: 15px !important;
    margin-bottom: 0 !important;
  }
  .footer-right .company p {
    font-size: 12px !important;
  }
  .footer-right .customer-care h3 {
    font-size: 15px !important;
    margin-bottom: 0 !important;
  }
  .footer-right .customer-care p {
    font-size: 12px !important;
  }
  .footer-right .services h3 {
    font-size: 15px !important;
    margin-bottom: 0 !important;
  }
  .footer-right .services p {
    font-size: 12px !important;
  }
  .footer-right .customer-care {
    margin-left: 20px;
  }
  .footer-right .services {
    margin-left: 20px;
  }
  .footer-right .company h3 {
    margin-bottom: 20px;
    font-size: 20px;
  }
  .footer-content {
    flex-direction: column;
  }

  .footer1 {
    height: fit-content;
  }
  .store .appstore img {
    width: 80%;
  }
  .store .playstore img {
    width: 80%;
  }
  .social {
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
    width: 25%;
  }
  .footer-line p {
    font-size: 14px;
    padding: 0 1px;
  }

}
@media only screen and (min-device-width: 376px) and (max-device-width: 425px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }
  .footer-left {
    /* width: 100%; */
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .footer-line p span {
    font-size: 11px;
    margin-left: 0;
    padding: 5px;
    display: block;
    text-align: center;
    width: 100%;
  }
  .footer-right {
    display: flex;
    align-items: flex-start;
    margin: 0;
    justify-content: center;
  }
  .footer-text h1 {
    font-size: 20px !important;
  }
  .footer-right .company {
    margin: 0;
  }
  .footer-right .company h3 {
    font-size: 15px !important;
    margin-bottom: 0 !important;
  }
  .footer-right .company p {
    font-size: 12px !important;
  }
  .footer-right .customer-care h3 {
    font-size: 15px !important;
    margin-bottom: 0 !important;
  }
  .footer-right .customer-care p {
    font-size: 12px !important;
  }
  .footer-right .services h3 {
    font-size: 15px !important;
    margin-bottom: 0 !important;
  }
  .footer-right .services p {
    font-size: 12px !important;
  }
  .footer-right .customer-care {
    margin-left: 20px;
  }
  .footer-right .services {
    margin-left: 20px;
  }
  .footer-right .company h3 {
    margin-bottom: 20px;
    font-size: 20px;
  }
  .footer-content {
    flex-direction: column;
  }

  .footer1 {
    height: fit-content;
  }
  .store .appstore img {
    width: 80%;
  }
  .store .playstore img {
    width: 80%;
  }
  .social {
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
    width: 25%;
  }
  .footer-line p {
    font-size: 14px;
    padding: 0 1px;
  }
}

@media only screen and (min-device-width: 200px) and (max-device-width: 375px) {
.footer-content {
    flex-direction: column;
    align-items: center;
  }
  .footer-left {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .footer-line p span {
    font-size: 11px;
    margin-left: 0;
    padding: 5px;
    display: block;
    text-align: center;
    width: 100%;
  }
  .footer-right {
    display: flex;
    align-items: flex-start;
    margin: 0;
    justify-content: center;
  }
  .footer-text h1 {
    font-size: 20px !important;
  }
  .footer-right .company {
    margin: 0;
  }
  .footer-right .company h3 {
    font-size: 15px !important;
    margin-bottom: 0 !important;
  }
  .footer-right .company p {
    font-size: 12px !important;
  }
  .footer-right .customer-care h3 {
    font-size: 15px !important;
    margin-bottom: 0 !important;
  }
  .footer-right .customer-care p {
    font-size: 12px !important;
  }
  .footer-right .services h3 {
    font-size: 15px !important;
    margin-bottom: 0 !important;
  }
  .footer-right .services p {
    font-size: 12px !important;
  }
  .footer-right .customer-care {
    margin-left: 20px;
  }
  .footer-right .services {
    margin-left: 20px;
  }
  .footer-right .company h3 {
    margin-bottom: 20px;
    font-size: 20px;
  }
  .footer-content {
    flex-direction: column;
  }

  .footer1 {
    height: fit-content;
  }
  .store .appstore img {
    width: 80%;
  }
  .store .playstore img {
    width: 80%;
  }
  .social {
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
    width: 25%;
  }
  .footer-line p {
    font-size: 14px;
    padding: 0 1px;
  }
}

</style>