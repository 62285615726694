<script>
import store from "@/state/store";
export default {
  data() {
    return {
      ContentisVisible: false,
      LoginMenuIsVisible: false,
      userId: store.getters["user/id"],
      userName: store.getters["user/name"]
    }
  },
  computed: {

  },
  methods: {
    showMenu() {
      this.ContentisVisible = !this.ContentisVisible
    },
    loginMenu() {
      this.LoginMenuIsVisible = !this.LoginMenuIsVisible
    },
    logout(){
      sessionStorage.clear();
      this.$router.push({
        path: '/logout'
      });
      location.reload();
    },
  }

}
</script>
<template>
  <div class="navbar-main">
    <div class="navbar-collapse">
      <!-- <b-button
        variant="secondary"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"
        ><i class="bx bx-bx bx-menu-alt-left" style="font-size: 30px"></i
      ></b-button> -->
      <b-button variant="secondary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasLeft"
        aria-controls="offcanvasLeft"><i class="bx bx-bx bx-menu-alt-left" style="font-size: 30px"></i></b-button>
    </div>
    <div class="logo">
      <router-link to="/"><img src="/img/logo-doctosoft.491c6828.png" alt="" height="30" /></router-link>
    </div>

    <div class="tabs">
      <!-- <router-link to="/getdoctor">Find Doctors</router-link>
      <router-link to="#">Video Consult</router-link>
      <router-link to="#">Medicines</router-link>
      <router-link to="#">Test Results</router-link>
      <router-link to="#">Surgeries</router-link> -->
    </div>

  <div class="menu">
      <div class="dropdown corporate-drop">
        <!-- <button class="dropbtn1">For Corporates</button> -->
        <div class="dropdown-content">
          <router-link to="#" class="link">Health Plans</router-link>
          <router-link to="#" class="link">Group Insurance</router-link>
        </div>
      </div>
      <div class="dropdown login-drop" v-if="!this.userId">
        <button class="dropbtn1">Login & Signup</button>
        <div class="dropdown-content">
          <router-link to="/login" class="link">Login</router-link>
          <router-link to="/register" class="link">Signup</router-link>
        </div>
      </div>
      <div class="btn support-btn">
        <router-link to="#">Support</router-link>
      </div>

      <div class="dropdown ms-sm-3 header-item topbar-user" v-if="this.userId">
        <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false">
          <span class="d-flex align-items-center">
            <!-- <img class="rounded-circle header-profile-user" src="@/assets/images/users/avatar-.jpg"
                    alt="Header Avatar" /> -->
            <!-- <div class="avatar-xxs">
              <div class="avatar-title rounded-circle bg-danger userprofile">
                {{ userName.charAt(0) }}
              </div>
            </div> -->

    <!-- ======User icon without pin======= -->
                    <i class="ri-account-circle-fill"></i>
    <!-- ======User icon with pin======= -->
            <!-- <i class="ri-account-pin-circle-fill"></i> -->
          </span>
        </button>
        <div class="dropdown-menu dropdown-menu-end">
          <h6 class="dropdown-header">Welcome {{ userName }}!</h6>
          <router-link class="dropdown-item" to="/user"><i
              class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
            <span class="align-middle">Profile</span>
          </router-link>
          <!-- <div class="dropdown-divider"></div> -->
          <button class="dropdown-item" @click="logout"><i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
            <span class="align-middle" data-key="t-logout">Logout</span>
          </button>
        </div>
      </div>

    </div>
  </div>
  <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasLeft" aria-labelledby="offcanvasLeftLabel">
    <div class="offcanvas-header">
      <!-- <h5 class="offcanvas-title" id="offcanvasLeftLabel">Recent Acitivity</h5> -->
      <img src="/img/logo-doctosoft.491c6828.png" alt="" height="30" />
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body p-0">
      <div class="nav-link">
        <router-link to="#">Find Doctors</router-link>
      </div>
      <div class="nav-link">
        <router-link to="#">Video Consult</router-link>
      </div>
      <div class="nav-link">
        <router-link to="#">Medicines</router-link>
      </div>
      <div class="nav-link">
        <router-link to="#">Test Results</router-link>
      </div>
      <div class="nav-link">
        <router-link to="#">Surgeries</router-link>
      </div>

      <div class="corporate">
        <div class="accountAction">
          <h4 @click="showMenu">For Corporates</h4>
          <a href="#" @click="showMenu" :class="{ rotate: ContentisVisible }"><i class="ri-arrow-right-s-line"></i></a>
        </div>
        <div class="menuContent" v-if="ContentisVisible">
          <div class="nav-link" style="padding-top:0">
            <router-link to="#" class="link">Health Plans</router-link>
          </div>
          <div class="nav-link" style="border-bottom: none;">
            <router-link to="#" class="link">Group Insurance</router-link>
          </div>
        </div>

      </div>

      <div class="loginSignup">
        <div class="accountAction">
          <h4 @click="loginMenu">Login & Signup</h4>
          <a href="#" @click="loginMenu" :class="{ rotate: LoginMenuIsVisible }"><i class="ri-arrow-right-s-line"></i></a>
        </div>
        <div class="menuContent" v-if="LoginMenuIsVisible">
          <div class="nav-link" style="padding-top:0">
            <router-link to="/login" class="link">Login</router-link>
          </div>
          <div class="nav-link" style="border-bottom: none;">
            <router-link to="/register" class="link">Signup</router-link>
          </div>
        </div>
      </div>

      <div class="nav-link">
        <router-link to="#">Support</router-link>
      </div>
    </div>
  </div>
<!-- <div
    class="offcanvas offcanvas-end"
    tabindex="-1"
    id="offcanvasRight"
    aria-labelledby="offcanvasRightLabel"
  >
    <div class="offcanvas-header border-bottom">
      
      <img src="/img/logo-doctosoft.491c6828.png" alt="" height="30" />
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body p-0">
      
    </div>
  </div> --></template>


<style>
.accountAction {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
}

.accountAction a {
  font-size: 25px;
  transition: 0.2s ease;
}

.accountAction h4 {
  margin-bottom: 0;
  width: 100%;
}

.rotate {
  transform: rotate(90deg);
}

.nav-dropdown {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.corporate {
  width: 100%;
  border-bottom: 1px solid #ccc;
}

.loginSignup {
  width: 100%;
  border-bottom: 1px solid #ccc;
}

.menuContent {
  margin-left: 30px;
}

.nav-link {
  width: fit-content;
  width: 100%;
  padding: 10px 0 10px 25px;
  border-bottom: 1px solid #ccc;
}

.nav-link a {
  font-size: 1rem;
}

/* .nav-link a:after {
  display: block;
  content: "";
  border-bottom: solid 3px #2aa2db;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.nav-link a:hover:after  {
  transform: scaleX(1);
} */

.hide {
  animation-name: hide;
  animation-duration: 1s;
  animation-timing-function: ease;
}

.animate {
  animation-name: moveinup;
  animation-duration: 1s;
  animation-timing-function: ease;
}

.navbar-collapse {
  display: none;
}

.collapse-item .tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.collapse-item .menu {
  display: flex;
  justify-content: center;
}

.navbar-main {
  background-color: #d1eefe;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  z-index: 100;
}

.tabs {
  display: flex;
  gap: 10px;
  margin: auto auto auto 10px;
}

.tabs a {
  font-size: 15px;
  color: black;
  display: inline-block;
  margin: 0;
  padding: 10px 5px;
}

.tabs a:after {
  display: block;
  content: "";
  border-bottom: solid 3px #2aa2db;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.tabs a:hover:after {
  transform: scaleX(1);
}


.btn {
  border-radius: 5px;
  padding: 1px;
}

.btn a {
  border: 1px solid #2aa2db;
  padding: 8px 20px;
  color: #1c8ec3;
  border-radius: 5px;
  transition: 0.2s ease;
}

.btn a:hover {
  transform: translateY(-3px);
  box-shadow: 0 0 6px #2aa2db;
}

.menu {
  display: flex;
  align-items: center;
  gap: 20px;
}

.dropbtn {
  border-radius: 5px;
  border: 1px solid #1c8ec3 !important;
  color: #1c8ec3;
  padding: 8px 16px;
  border: none;
  background-color: transparent;
  transition: 0.2s ease;
}

.dropbtn1 {
  border-radius: 5px;
  background-color: #1c8ec3;
  color: white;
  padding: 8px 16px;
  border: none;
  border: 1px solid transparent;
  transition: 0.2s ease;
}

.dropdown {
  position: relative;
  display: inline-block;
  margin: 0 !important;
}

.dropdown-content {
  display: none;
  border-radius: 5px;
  position: absolute;
  background-color: #9ccfe7;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  overflow: hidden;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: 0.2s ease;
}

.dropdown-content a:hover {
  background-color: #d1f0ff;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  border: none;
  background-color: #1c8ec3;
  color: white;
}

.dropdown:hover .dropbtn1 {
  border: 1px solid #2aa2db;
  background-color: transparent;
  color: #1c8ec3;
}

/* ==============================user logo  */
.topbar-user{
  height: fit-content;
  background-color: transparent;
}
.topbar-user i{
  font-size: 35px;
  color: #1c8ec3;
}
.avatar-xxs{
  height: 2.5rem;
  width: 2.5rem;
} 
.avatar-xxs .avatar-title {
    align-items: center;
    background-color: #405189;
    color: #fff;
    display: flex;
    font-weight: 200;
    height: 100%;
    font-size: 1.5rem;
    justify-content: center;
    width: 100%;
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1440px){
  h3 {
    font-size: 18px !important;
  }
  h1 {
    font-size: 25px !important;
  }
  .navbar-main {
    height: 65px;
    justify-content: flex-start;
  }
  .navbar-main .navbar-collapse {
    max-width: fit-content;
    text-align: left;
  }
  .navbar-collapse,
  .collapse-item {
    display: none;
  }

  .navbar-main .logo img {
    height: 30px;
  }

  .tabs {
    gap: 5px;
  }

  .tabs a {
    font-size: 13px;
    padding: 10px 5px;
  }

  .logo {
    margin: 0 10px;
  }
  .menu {
    gap: 10px;
  }
  .btn a {
    height: 35px;
    font-size: 12px;
  }

  .dropbtn1 {
    font-size: 12px;
    padding: 5px 10px;
    height: 35px;
  }
  .dropdown-content {
    min-width: 125px;
  }

  .dropdown-content a {
    font-size: 12px;
    padding: 8px 5px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px){
  .navbar-main {
    height: fit-content;
    width: 100%;
    padding-bottom: 20px;
    justify-content: flex-start;
    align-items: baseline;
  }
  .navbar-main .logo img {
    height: 30px;
  }
  .navbar-collapse {
    display: block;
    padding: 10px;
    /* text-align: right; */
  }
  .collapse-item {
    background-color: #d1eefe;
  }
  .tabs {
    justify-content: center;
    gap: 20px;
    width: 100%;
    margin: 0;
    display: none;
  }

  .tabs a {
    font-size: 14px;
  }

  .logo {
    margin: 0 10px 0 10px;
  }

  .btn a {
    height: 30px;
    padding: 5px 20px;
  }
  .menu {
    margin-top: 10px;
    /* display: none; */
  }
  .menu .corporate-drop {
    display: none;
  }
  .menu .login-drop {
    display: none;
  }
  .menu .support-btn {
    display: none;
  }
  .dropbtn1 {
    padding: 2px 10px;
    height: 30px;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .topbar-user i {
    font-size: 30px;
    color: #1c8ec3;
  }
  .navbar-main {
    height: fit-content;
    padding-bottom: 20px;
    justify-content: flex-start;
    align-items: baseline;
  }

  .navbar-main .logo img {
    height: 30px;
  }
  .navbar-collapse {
    display: block;
    padding: 10px;
    /* text-align: right; */
  }
  .collapse-item {
    background-color: #d1eefe;
  }

  .tabs {
    display: none;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    margin: 0;
    flex-direction: column;
  }
  .tabs a {
    font-size: 13px !important;
  }

  .btn a {
    height: 25px;
    padding: 3px 15px;
  }
  .menu {
    /* display: none; */
    margin-top: 10px;
  }
  .menu .corporate-drop {
    display: none;
  }
  .menu .login-drop {
    display: none;
  }
  .menu .support-btn {
    display: none;
  }
  .dropbtn1 {
    height: 25px;
    padding: 2px 7px;
  }

}
@media only screen and (min-device-width: 376px) and (max-device-width: 425px){
  .navbar-main {
    height: fit-content;
    /* padding-bottom: 20px; */
    justify-content: flex-start;
    align-items: baseline;
  }
  .navbar-collapse {
    display: block;
    padding: 10px;
    /* text-align: right; */
  }
  .navbar-main .logo {
    margin: 0 10px;
  }

  .navbar-main .logo img {
    height: 30px;
  }
  .collapse-item {
    padding: 10px;
    background-color: #d1eefe;
  }

  .tabs {
    justify-content: center;
    align-items: center;
    align-content: center;
    /* gap: 20px; */
    width: 100%;
    margin: 0;
    flex-direction: column;
    display: none;
  }

  .btn a {
    height: 25px;
    padding: 3px 15px;
  }
  .menu {
    margin-top: 10px;
    /* display: none; */
  }
  .menu .corporate-drop {
    display: none;
  }
  .menu .login-drop {
    display: none;
  }
  .menu .support-btn {
    display: none;
  }
  .dropbtn1 {
    height: 25px;
    padding: 2px 7px;
  }

}
@media only screen and (min-device-width: 200px) and (max-device-width: 375px){
   .topbar-user i {
    font-size: 28px;
    color: #1c8ec3;
  }
  .navbar-main {
    height: fit-content;
    padding-bottom: 20px;
    justify-content: flex-start;
    align-items: baseline;
  }
  .navbar-collapse {
    display: block;
    padding: 10px;
    /* text-align: right; */
  }
  .collapse-item {
    padding: 10px;
    background-color: #d1eefe;
  }
  /* .navbar-main .logo{
    margin: auto 10px;
  } */
  .navbar-main .logo img {
    height: 30px;
  }

  .tabs {
    justify-content: center;
    align-items: center;
    align-content: center;
    /* gap: 20px; */
    width: 100%;
    margin: 0;
    flex-direction: column;
    display: none;
  }

  .btn a {
    height: 25px;
    padding: 3px 2px;
  }
  .menu {
    /* display: none; */
    margin-top: 10px;
  }
  .menu .corporate-drop {
    display: none;
  }
  .menu .login-drop {
    display: none;
  }
  .menu .support-btn {
    display: none;
  }
  .dropbtn1 {
    height: 25px;
    font-size: 12px;
    padding: 2px 2px;
  }

}
</style>
